import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentUser, setNotification, toggleSidebar } from '../../redux/actions.js';

import Ajax from '../../Ajax';
import { useHandleError } from '../../customHooks';

import { Button, TextField, InputAdornment, Typography, Container, CircularProgress } from '@material-ui/core';

import EmailIcon from '@material-ui/icons/Email';

const SignIn = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.user.currentUser);
  const handleError = useHandleError(dispatch);

  const [loggingIn, setLoggingIn] = React.useState(false);
  const [form, setForm] = React.useState({
    email: '',
    attempted:  false
  });

  React.useEffect(() => {
    dispatch(toggleSidebar(false));

    return () => {
      dispatch(toggleSidebar(true));
    }
  }, [])

  React.useEffect(() => {
    const fetchCurrentUser = async () => {      
      try {
        const currentUser = await Ajax.getCurrentUser();

        if (currentUser) {
          dispatch(setCurrentUser(currentUser));
          setLoggingIn(false);

        } else {
          localStorage.removeItem('wwl-mpp-token');
          setLoggingIn(false);
          dispatch(setNotification({ msg: 'Uh oh, something went wrong!', severity: 'error', subMessage: 'Please try again or contact support@workweeklunch.com' }));
        }
    
      } catch(e) {
          let errMessage = e.response && e.response.data && e.response.data.data && e.response.data.data.length 
            ? e.response.data.data[0]?.messages[0]?.message || e.message
            : e.message;

          setLoggingIn(false);
          dispatch(setNotification({ msg: errMessage, severity: 'error', subMessage: 'Please try again or contact support@workweeklunch.com' }));
          localStorage.removeItem('wwl-mpp-token');
      }
    }

    const token = localStorage.getItem('wwl-mpp-token');

    if (token) {
      setLoggingIn(true);

      fetchCurrentUser();
    } else {
      dispatch(setCurrentUser(null));
    }

  }, []);

  const handleSignIn = async (e) => {
    e.preventDefault();
    let { email } = form;
    email  = email.toLowerCase().trim();

    if (EmailIcon) {
      try {
        setLoggingIn(true);

        const res = await Ajax.login({ email });

        const { user, token } = res;

        if (!user.isAdmin) throw Error('Not registered as an admin user.');

        localStorage.setItem('wwl-mpp-token', token);
        dispatch(setCurrentUser(user));
        dispatch(toggleSidebar(true));

      } catch (e) {
          handleError(e);
          setLoggingIn(false);
          localStorage.removeItem('wwl-mpp-token');
      }

    } else {
      dispatch(setNotification({ msg: 'All fields are required.', severity: 'error' }));
      setLoggingIn(false);
    }

    const update = { ...form, ...{ attempted: true }};
    setForm(update);
  }

  const handleChange = (e) => {
    const update = { ...form, ...{[e.target.name]: e.target.value }};
    setForm(update);
  }

  const handleKeyDown = (e) => {
    let code = e.keyCode || e.which;
    if(code === 13) { // Enter Key
      handleSignIn(e);
    }
  }

  if (currentUser && currentUser.email) {
    return (
      <Redirect to='/users' />
    )
  }

  return (
    <Container component="main" maxWidth="xs">
      <div style={{ marginTop: 0, paddingTop: '10%' }}>
        <img style={{ width: '100%' }} alt='Workweek Lunch Logo' src='https://wwl-api-images.s3-us-west-2.amazonaws.com/wwl-logo.png' />
      
        <Typography component="h1" variant="h5" style={{fontSize: '1.5rem', fontWeight: 'bold', margin: '5% 0 5% 0', textAlign: 'center'}}>
          Workweek Lunch Admin Dashboard
        </Typography>

        <form noValidate style={{ backgroundColor: '#ffffff', padding: '10%', borderRadius: '15%' }}>
          <TextField
            variant="outlined"
            error={form.attempted && form.email.length === 0}
            margin="normal"
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            autoComplete="email"
            autoFocus
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />

          <Button fullWidth onClick={loggingIn ? (e) => e.preventDefault() : handleSignIn}>
            { loggingIn ? <CircularProgress style={{ color: '#ffffff', height: '30px', width: '30px' }} /> : 'Sign In' }
          </Button>

        </form>
      </div>
    </Container>
  )


}

export default SignIn;