import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { setNotification, updateAllIngredients} from '../../redux/actions';
import { useDeviceStyles } from '../../customHooks';
import Ajax from '../../Ajax';

import { Button, TextField, MenuItem } from '@material-ui/core';

import Loading from '../../Components/Helpers/Loading';

const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    width: '100%',
    maxWidth: '1000px',
    margin: '10px 0',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

const Ingredient = () => {
  const dispatch = useDispatch();
  const classes = useDeviceStyles({ styles });

  const storedIngredients = useSelector(state => state.recipes.allIngredients);

  const [redirect, setRedirect] = React.useState(null);
  const [entry, setEntry] = React.useState(null);
  const [activeEdits, setActiveEdits] = React.useState(false);
  const [updates, setUpdates] = React.useState({
    title: '',
    category: '',
    density: 0
  });

  React.useEffect(() => {
    const fetchEntry = async (id) => {
      const res = await Ajax.getIngredient(id);
      setEntry(res);
      setUpdates({...res});
    }

    const id = window.location.href.match(/(?<=\/ingredients\/)[\d]+/g);

    if (!entry && id && id[0]) {
      fetchEntry(id[0]);
    }
  }, []);

  const handleChange = (e) => {
    let val = e.target.value ? e.target.value : e.target.checked;
    if (e.target.name === 'density') val = parseFloat(val);
    setActiveEdits(true);
    setUpdates({...updates, ...{ [e.target.name]: val }});
  }

  const handleSave = async () => {
    try {
      const res = await Ajax.updateIngredient(updates.id, updates);

      setEntry(res);
      setUpdates({...res});

      dispatch(setNotification({ msg: 'Entry updated', severity: 'info' }));

      if (Boolean(storedIngredients.find(i => i.id === res.id))) {
        dispatch(updateAllIngredients([
          ...storedIngredients.filter(i => i.id !== res.id),
          res,
        ]));

      } else {
        dispatch(updateAllIngredients([
          ...storedIngredients,
          res,
        ]))
      }

      setActiveEdits(false);
    } catch (e) {
      dispatch(setNotification({ msg: e.message, severity: 'error' }));
    }
  }

  const handleDelete = async () => {
    const confirm = window.confirm(`Delete ingredient ${entry.title}?`);
    if (!confirm) return;

    try {
      await Ajax.deleteIngredient(entry.id);

      dispatch(setNotification({ msg: 'Entry deleted', severity: 'info' }));
      setRedirect('/ingredients');
    } catch (e) {
      dispatch(setNotification({ msg: e.message, severity: 'error' }));
    }
  }

  if (!entry) {
    return (
      <Loading height='25px' width='25px' />
    )
  }

  if (redirect) {
    return (
      <Redirect push to={redirect} />
    )
  }

  // TODO: associated recipes

  return (
    <main className={classes.main}>

      <div className={classes.row}>
        <Button disabled={!activeEdits} onClick={handleSave}>Save</Button>
        <Button onClick={handleDelete}>Delete</Button>
      </div>

      <div className={classes.row}>
        <TextField id='Title' label='Title' value={updates.title} name='title' onChange={handleChange} />
      </div>

      <div className={classes.row}>
        <TextField id='Category' label='Category' value={updates.category} select name='category' onChange={handleChange}>
          <MenuItem value='refrigerated'>Refrigerated</MenuItem>
          <MenuItem value='produce'>Produce</MenuItem>
          <MenuItem value='pantry'>Pantry</MenuItem>
        </TextField>
      </div>

      <div className={classes.row}>
        <TextField type='number' id='Density' label='Density' value={updates.density} name='density' onChange={handleChange} />
      </div>

    </main>
  )
}

export default Ingredient;