export const defaultValues = {
  title: '',
  reviewed: false,
  isDraft: false,
  isPublic: false,
  publishedDate: '',
  primaryImage: '',
  description: '',
  prepTime: 0,
  cookTime: 0,
  servings: 0,
  tags: '',
  rating: 0,
  omnivoreInstructions: '',
  vegetarianInstructions: '',
  veganInstructions: '',
  dairyFreeInstructions: '',
  extraData: {}
}