import React from 'react';
import { useDeviceStyles } from '../../customHooks';

import { TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const styles = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    width: 500,
    margin: '15px 0',
  },
  input: {
    width: '90%'
  }
}

const Search = (props) => {
  const { onChange, searchFunction, onSearch } = props;
  const classes = useDeviceStyles({ styles });

  const [searchVal, setSearch] = React.useState('');
  const [timer, setTimer] = React.useState(null);

  // Only requests the search if the field is non-empty and 
  // hasn't been changed for 500 ms
  React.useEffect(() => {
    const conductSearch = async () => {
      const res = await searchFunction(searchVal);
      onChange(res);
    }

    if (searchVal) {

      if (timer) {
        clearTimeout(timer);
      }

      let timeout = setTimeout(() => {
        conductSearch();
        onSearch(searchVal);
      }, 500)

      setTimer(timeout);
    } else {
      clearTimeout(timer);
      onSearch('');
    }

    return () => {
      clearTimeout(timer);
    }

  }, [searchVal])

  return (
    <div className={classes.wrapper}>
      <SearchIcon  />
      <TextField 
        className={classes.input}
        variant='filled'
        value={searchVal}
        onChange={(e) => setSearch(e.target.value)}
      />
    </div>
  )
}

export default Search;