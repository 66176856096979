import React from 'react';
import { useDeviceStyles } from '../../customHooks';

import { TextField, MenuItem } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const IngInput = ({ name, type, label, value, onChange, options, autoFocus, width }) => {

  const classes = useDeviceStyles({
    styles: {
      input: {
        margin: '0 10px',
        width: width,
        minWidth: 0,
        '& > div': {
          width: width
        }
      }
    }
  });

  const handleChange = (e) => {
    onChange(name, e.target.value);
  }

  const handleAutoChange = (e, val) => {
    const selection = options.find(o => o.prop === val);
    if (selection) {
      onChange(name, selection.prop);
    }
  }


  const handleKeyDown = (e) => {
    let code = e.keyCode || e.which;
    if(code === 13) { // Enter Key
      if (!e.target || !e.target.value) return;

      handleAutoChange(e, e.target.value);
      e.target.blur();
    }
  }

  if (type === 'select') {
    return (
      <TextField
        className={classes.input}
        select
        label={label}
        value={value}
        onChange={handleChange}
        autoFocus={autoFocus}
      >
        {options.map((option) => (
          <MenuItem key={option.prop} value={option.prop}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    )
  }

  if (type === 'auto') {
    return (
      <Autocomplete
        onChange={handleAutoChange}
        value={value}
        options={options.map(i => i.prop)}
        onKeyDown={handleKeyDown}
        renderInput={(params) => (
          <TextField {...params} label={label} margin="normal" variant="outlined" autoFocus={autoFocus} />
        )}
      />
    )
  }

  return (
    <TextField onChange={handleChange} label={label} type={type} className={classes.input} value={value} autoFocus={autoFocus} />
  )
}

export default IngInput;