import axios from 'axios';

const baseUrl = 'https://www.wwlapi.com';
// const baseUrl = 'http://127.0.0.1:3001'

export default class Ajax {

  // User
  static async login(payload) {
    try {
      const res = await axios.post(`${baseUrl}/users/login/admin`, payload);
      return res.data;

    } catch (e) {
      throw e;
    }
  }

  static async getCurrentUser() {
    try {
      const token = localStorage.getItem('wwl-mpp-token');

      const headers = {
        Authorization: `Bearer ${token}`
      }

      const res = await axios.get(`${baseUrl}/users`, { headers });
      return res?.data || null;

    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async updateCurrentUser(params, id) {
    try {
      const token = localStorage.getItem('wwl-mpp-token');

      const headers = {
        Authorization: `Bearer ${token}`
      }

      const res = await axios.put(`${baseUrl}/users/${id}`, params, { headers });

      return res.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // Recipes
  static async getAllRecipes(startIndex = -1, perPage = 25) {
    try {
      const token = localStorage.getItem('wwl-mpp-token');

      const headers = {
        Authorization: `Bearer ${token}`
      }

      const res = await axios.get(`${baseUrl}/recipes/all?index=${startIndex}&per=${perPage}`, { headers });

      return res?.data || [];

    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async getRecipe(id) {
    try {
      const token = localStorage.getItem('wwl-mpp-token');

      if (!token) return null;

      const headers = {
        Authorization: `Bearer ${token}`
      }

      const res = await axios.get(`${baseUrl}/recipes/${id}`, { headers });
      return res.data;

    } catch (e) {
      console.error(e);
      return null;
    }
  }

  // Ingredients 
  static async getAllIngredients(startIndex = -1, perPage = 25) {
    try {
      const token = localStorage.getItem('wwl-mpp-token');

      const headers = {
        Authorization: `Bearer ${token}`
      }

      const res = await axios.get(`${baseUrl}/ingredients/all?index=${startIndex}&per=${perPage}`, { headers });
      return res?.data || [];

    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // Meal Plans
  static async getAllAdminMealPlans() {
    try {
      const token = localStorage.getItem('wwl-mpp-token');

      const headers = {
        Authorization: `Bearer ${token}`
      }

      const res = await axios.get(`${baseUrl}/plans/admin`, { headers });
      return res.data;

    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async getMealPlan(id) {
    try {
      const token = localStorage.getItem('wwl-mpp-token');

      const headers = {
        Authorization: `Bearer ${token}`
      }

      const res = await axios.get(`${baseUrl}/plans/${id}`, { headers });

      return res.data;

    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async updateMealPlan(id, body) {
    try {
      const token = localStorage.getItem('wwl-mpp-token');

      const headers = {
        Authorization: `Bearer ${token}`
      }

      const res = await axios.put(`${baseUrl}/plans/${id}`, body, { headers });
      return res.data;

    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async deleteMealPlan(id) {
    try {
      const token = localStorage.getItem('wwl-mpp-token');

      const headers = {
        Authorization: `Bearer ${token}`
      }

      const res = await axios.delete(`${baseUrl}/plans/${id}`, { headers });
      return res.data;

    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async getAllUserMealPlans(userId) {
    try {
      if (!userId) return null;

      const token = localStorage.getItem('wwl-mpp-token');

      const headers = {
        Authorization: `Bearer ${token}`
      }

      const res = await axios.post(`${baseUrl}/user-meal-plans/user`, { userId }, { headers });
      return res.data;

    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async createMealPlan(body) {
    try {
      const token = localStorage.getItem('wwl-mpp-token');

      const headers = {
        Authorization: `Bearer ${token}`
      }

      const res = await axios.post(`${baseUrl}/plans`, body, { headers });
      return res.data;

    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async searchMealPlans(query) {
    try {
      const token = localStorage.getItem('wwl-mpp-token');

      const headers = {
        Authorization: `Bearer ${token}`
      }

      const res = await axios.get(`${baseUrl}/plans/search/?search=${query}`, { headers });
      return res?.data || [];

    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // Ratings
  static async getAllRatings(recipeId) {
    try {
      const token = localStorage.getItem('wwl-mpp-token');

      const headers = {
        Authorization: `Bearer ${token}`
      }

      const res = await axios.get(`${baseUrl}/recipes/ratings/${recipeId}`, { headers });

      return res?.data || [];

    } catch (e) {
      console.error(e);
      return []
    }
  }

  // Admin Only ****************************
  static async uploadFile(file, bucket) {
    try {
      const token = localStorage.getItem('wwl-mpp-token');

      const formData = new FormData();
      formData.append('file', file);

      const res = await axios.post(`${baseUrl}/images/${bucket}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        }
      });

      return {
        '800': res.data
      }

    } catch (e) {
      throw e;
    }
  }

  static async deleteFile(fileName, bucket) {
    try {
      const token = localStorage.getItem('wwl-mpp-token');

      const res = await axios.delete(`${baseUrl}/images/${bucket}/${fileName}`, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        }
      });

      return res.data;

    } catch (e) {
      throw e;
    }
  }

  static async getAllUsers(startIndex = 1, perPage = 25) {
    try {
      const token = localStorage.getItem('wwl-mpp-token');

      const headers = {
        Authorization: `Bearer ${token}`
      }

      const res = await axios.get(`${baseUrl}/users/all?index=${startIndex}&per=${perPage}`, { headers });
      return res?.data || [];

    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async countUsers() {
    try {
      const token = localStorage.getItem('wwl-mpp-token');

      const headers = {
        Authorization: `Bearer ${token}`
      }

      const res = await axios.get(`${baseUrl}/users/count`, { headers });
      return res?.data?.count || 0;

    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async searchUsers(query) {
    try {
      const token = localStorage.getItem('wwl-mpp-token');

      const headers = {
        Authorization: `Bearer ${token}`
      }

      const res = await axios.get(`${baseUrl}/users/search/?search=${query}`, { headers });
      return res?.data || [];

    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async getUser(id) {
    try {
      const token = localStorage.getItem('wwl-mpp-token');

      const headers = {
        Authorization: `Bearer ${token}`
      }

      const res = await axios.get(`${baseUrl}/users/${id}`, { headers });
      return res?.data || null;

    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async updateUser(id, data) {
    try {
      const token = localStorage.getItem('wwl-mpp-token');

      const headers = {
        Authorization: `Bearer ${token}`
      }

      const res = await axios.put(`${baseUrl}/users/${id}`, data, { headers });
      return res?.data || null;

    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async deleteUser(id) {
    try {
      const token = localStorage.getItem('wwl-mpp-token');

      const headers = {
        Authorization: `Bearer ${token}`
      }

      const res = await axios.delete(`${baseUrl}/users/${id}`, { headers });
      return res?.data || null;

    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // Ingredients
  static async countIngredients() {
    try {
      const token = localStorage.getItem('wwl-mpp-token');

      const headers = {
        Authorization: `Bearer ${token}`
      }

      const res = await axios.get(`${baseUrl}/ingredients/count`, { headers });
      return res?.data?.count || 0;

    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async createIngredient(data) {
    try {
      const token = localStorage.getItem('wwl-mpp-token');

      const headers = {
        Authorization: `Bearer ${token}`
      }

      const res = await axios.post(`${baseUrl}/ingredients`, data, { headers });
      return res?.data || null;

    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async searchIngredients(query) {
    try {
      const token = localStorage.getItem('wwl-mpp-token');

      const headers = {
        Authorization: `Bearer ${token}`
      }

      const res = await axios.get(`${baseUrl}/ingredients/search/?search=${query}`, { headers });
      return res?.data || [];

    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async getIngredient(id) {
    try {
      const token = localStorage.getItem('wwl-mpp-token');

      const headers = {
        Authorization: `Bearer ${token}`
      }

      const res = await axios.get(`${baseUrl}/ingredients/${id}`, { headers });
      return res.data;

    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async updateIngredient(id, data) {
    try {
      const token = localStorage.getItem('wwl-mpp-token');

      const headers = {
        Authorization: `Bearer ${token}`
      }

      const res = await axios.put(`${baseUrl}/ingredients/${id}`, data, { headers });
      return res?.data || null;

    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async deleteIngredient(id) {
    try {
      const token = localStorage.getItem('wwl-mpp-token');

      const headers = {
        Authorization: `Bearer ${token}`
      }

      const res = await axios.delete(`${baseUrl}/ingredients/${id}`, { headers });
      return res?.data || null;

    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // Recipes

  static async countRecipes() {
    try {
      const token = localStorage.getItem('wwl-mpp-token');

      const headers = {
        Authorization: `Bearer ${token}`
      }

      const res = await axios.get(`${baseUrl}/recipes/count`, { headers });
      return res?.data?.count || 0;

    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async createRecipe(data) {
    try {
      const token = localStorage.getItem('wwl-mpp-token');

      const headers = {
        Authorization: `Bearer ${token}`
      }

      const res = await axios.post(`${baseUrl}/recipes`, data, { headers });
      return res?.data || null;

    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async updateRecipe(id, data) {
    try {
      const token = localStorage.getItem('wwl-mpp-token');

      const headers = {
        Authorization: `Bearer ${token}`
      }

      const res = await axios.put(`${baseUrl}/recipes/${id}`, data, { headers });
      return res?.data || null;

    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async searchRecipes(val, allIngredients) {
    try {
      const token = localStorage.getItem('wwl-mpp-token');

      const headers = {
        Authorization: `Bearer ${token}`
      }

      let queriedWords = [];
      let queriedIngredients = [];

      let query = val.split(',');

      query.forEach(word => {
        const storedIng = allIngredients.find(ing => ing.title.trim().toLowerCase() === word.trim().toLowerCase());

        if (storedIng) {
          queriedIngredients.push(storedIng.id);
        } else {
          queriedWords.push(word);
        }
      });

      query = `query=${queriedWords.join(',')}&ingredients=${queriedIngredients.join(',')}`

      const res = await axios.get(`${baseUrl}/recipes/search/?${query}&index=-1`, { headers });
      return res?.data || [];

    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async getRecipeIngredientLists(id) {
    try {
      const token = localStorage.getItem('wwl-mpp-token');

      const headers = {
        Authorization: `Bearer ${token}`
      }

      const res = await axios.get(`${baseUrl}/recipes/${id}/ingredients`, { headers });
      return res?.data || [];

    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async updateIngredientList(id, data) {
    try {
      const token = localStorage.getItem('wwl-mpp-token');

      const headers = {
        Authorization: `Bearer ${token}`
      }

      const res = await axios.put(`${baseUrl}/ingredient-lists/${id}`, data, { headers });
      return res?.data || null;

    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async getListIngredients(id) {
    try {
      const token = localStorage.getItem('wwl-mpp-token');

      const headers = {
        Authorization: `Bearer ${token}`
      }

      const res = await axios(`${baseUrl}/ingredient-lists/${id}/ingredients`, { headers });
      return res?.data || [];

    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async deleteRecipe(id) {
    try {
      const token = localStorage.getItem('wwl-mpp-token');

      const headers = {
        Authorization: `Bearer ${token}`
      }

      const res = await axios.delete(`${baseUrl}/recipes/${id}`, { headers });
      return res?.data || null;

    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // 

  static async countMealPlans() {
    try {
      const token = localStorage.getItem('wwl-mpp-token');

      const headers = {
        Authorization: `Bearer ${token}`
      }

      const res = await axios.get(`${baseUrl}/plans/count`, { headers });
      return res?.data?.count || 0;

    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async getAllMealPlans(startIndex = -1, perPage = 25) {
    try {
      const token = localStorage.getItem('wwl-mpp-token');

      const headers = {
        Authorization: `Bearer ${token}`
      }

      const res = await axios.get(`${baseUrl}/plans/all?index=${startIndex}&per=${perPage}`, { headers });
      return res?.data || 0;

    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // ***********
}