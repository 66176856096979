export const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    width: '100%',
    maxWidth: '1000px',
    margin: '20px 0',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    width: '100%',
    '& div': {
      width: '100%',
      '& > input': {
        width: '100%'
      }
    }
  },
  image: {
    width: '800px'
  },
  icon: {
    color: '#5EC9BE',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  filePicker: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  planWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: 1300,
    minHeight: 750,
    justifyContent: 'space-between'
  },
  recipeSelect: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  selectedRecipe: {
    display: 'flex',
    width: 500,
    flexWrap: 'wrap',
    marginBottom: 15,

    '& > p': {
      width: '100%',
    }
  },
  option: {
    margin: '5px 0',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  planGrid: {
    width: '100%',
    minHeight: 500,
    display: 'flex',
    flexDirection: 'column',
  },
  rowWrapper: {
    display: 'flex',
    width: '100%',
    height: 200,
    flexDirection: 'column',
    margin: '5px 0'
  },
  gridRow: {
    width: '100%',
    height: 150,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  grid: {
    width: 175,
    height: 175,
    border: '1px solid #101C70'
  },
  recPreview: {
    display: 'flex',
    height: '80%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
}