import update from 'immutability-helper';
import { SET_CURRENT_USER, UPDATE_CURRENT_USER, SIGN_USER_OUT, SET_CURRENT_PAGE, UPDATE_FAVORITE_RECIPES, UPDATE_USER_MEAL_PLANS, SET_PREV_LOCATION } from './actions';
import { SET_CURRENT_RECIPE, UPDATE_ALL_RECIPES, UPDATE_FILTERED_RECIPES, UPDATE_ALL_INGREDIENTS, UPDATE_SAVED_RECIPES, UPDATE_BOOKMARKED_RECIPES, SET_WORKSHEET_OPEN } from './actions';
import { SET_WEEKLY_MEAL_PLAN, SET_SHOPPING_LIST, UPDATE_MEAL_PLANS, SET_CURRENT_MEAL_PLAN, SET_DRAFT_MEAL_PLAN } from './actions';
import { SET_CURRENT_SEARCH_TYPE, SET_CURRENT_SEARCH_VALUE, SET_CURRENT_SEARCH_PAGE, SET_NOTIFICATION } from './actions';
import { TOGGLE_SIDEBAR } from './actions';
import { combineReducers } from 'redux';

const initialUserState = {
  currentUser: {},
  favoriteRecipes: [],
  userMealPlans: null,
  prevLocation: null,
};

const initialRecipeState = {
  currentRecipe: {},
  allRecipes: [],
  filteredRecipes: [],
  recipeFilters: [],
  allIngredients: [],
  savedRecipes: [],
  bookmarkedRecipes: [],
  worksheetIsOpen: false,
  currentSearchType: 'ingredients',
  currentSearchValue: [],
  currentSearchPage: 1
}

const initialNavState = {
  currentPage: 'users',
  sidebarIsOpen: false,
  notification: {
    msg: '',
    severity: ''
  },
};

const initialMealPlanState = {
  weeklyMealPlan: null,
  allMealPlans: null,
  currentMealPlan: null,
  draftMealPlan: null,
  shoppingList: null,
}

const userReducer = (state = initialUserState, action) => {
  switch(action.type) {
    case SET_CURRENT_USER:
      return update(state, {
        currentUser: {$set: action.payload}
      });

    case UPDATE_CURRENT_USER:
      return update(state, {
        currentUser: {$set: action.payload}
      })

    case SIGN_USER_OUT:
      localStorage.removeItem('wwl-mpp-token');
      window.location.reload();
      return update(state, {
        currentUser: {}
      }); 

    case UPDATE_FAVORITE_RECIPES:
      return update(state, {
        favoriteRecipes: {$set: action.payload}
      });

    case UPDATE_USER_MEAL_PLANS:
      return update(state, {
        userMealPlans: {$set: action.payload}
      });

    case SET_PREV_LOCATION:
      return update(state, {
        prevLocation: {$set: action.payload}
      });

    default:
      return state;
  };
};

const recipeReducer = (state = initialRecipeState, action) => {
  switch(action.type) {
    case SET_CURRENT_RECIPE:
      return update(state, {
        currentRecipe: {$set: action.payload}
      });
  
    case UPDATE_ALL_RECIPES:
      return update(state, {
        allRecipes: {$set: action.payload}
      });
    
    case UPDATE_FILTERED_RECIPES:
      return update(state, {
        filteredRecipes: {$set: action.payload}
      });

    case UPDATE_SAVED_RECIPES:
      return update(state, {
        savedRecipes: {$set: action.payload}
      });

    case UPDATE_BOOKMARKED_RECIPES:
      return update(state, {
        bookmarkedRecipes: {$set: action.payload}
      });
          
    case UPDATE_ALL_INGREDIENTS:
      return update(state, {
        allIngredients: {$set: action.payload}
      });

    case SET_WORKSHEET_OPEN:
      return update(state, {
        worksheetIsOpen: {$set: action.payload}
      });

    case SET_CURRENT_SEARCH_TYPE:
      return update(state, {
        currentSearchType: {$set: action.payload}
      });

    case SET_CURRENT_SEARCH_VALUE:
      return update(state, {
        currentSearchValue: {$set: action.payload}
      });

      case SET_CURRENT_SEARCH_PAGE:
        return update(state, {
          currentSearchPage: {$set: action.payload}
        });

    default:
      return state;
  };
}

const mealPlanReducer = (state = initialMealPlanState, action) => {
  switch(action.type) {
    case SET_WEEKLY_MEAL_PLAN:
      return update(state, {
        weeklyMealPlan: {$set: action.payload}
      });

    case SET_SHOPPING_LIST:
      return update(state, {
        shoppingList: {$set: action.payload}
      });

    case UPDATE_MEAL_PLANS:
      return update(state, {
        allMealPlans: {$set: action.payload}
      });

    case SET_CURRENT_MEAL_PLAN:
      return update(state, {
        currentMealPlan: {$set: action.payload}
      });

    case SET_DRAFT_MEAL_PLAN:
      return update(state, {
        draftMealPlan: {$set: action.payload}
      });

    default:
      return state;
  };
}

const navigationReducer = (state = initialNavState, action) => {
  switch(action.type) {
    case SET_CURRENT_PAGE:
      return update(state, {
        currentPage: {$set: action.payload}
      });
    
    case TOGGLE_SIDEBAR:
      return update(state, {
        sidebarIsOpen: {$set: action.payload}
      });

    case SET_NOTIFICATION:
      return update(state, {
        notification: {$set: action.payload}
      });

    default:
      return state;
  };
}

const allReducers = combineReducers({
  user: userReducer,
  navigation: navigationReducer,
  recipes: recipeReducer,
  mealPlan: mealPlanReducer,
});

export default allReducers;