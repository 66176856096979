import React from 'react';
import Ajax from '../../Ajax';

import Entries from '../../Components/Entries';

const Users = () => {
  const headCells = ['ID', 'Email', 'Name', 'MemberfulID', 'Admin', 'Active'];
  const rowProps = ['id', 'email', 'name', 'memberfulId', 'isAdmin', 'isActive'];

  return (
    <Entries 
      headCells={headCells}
      rowProps={rowProps}
      searchFunction={Ajax.searchUsers} 
      requestData={Ajax.getAllUsers} 
      requestCount={Ajax.countUsers} 
      redirectLink='users'
    />
  )

}

export default Users;