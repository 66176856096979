import React from 'react';
import { useDropzone } from 'react-dropzone';
import Ajax from '../../Ajax';

import { Typography, Button, CircularProgress } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';

const FilePicker = (props) => {
  const { onUpload, onError, className, bucket } = props;

  const [file, setFile] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const readFile = async (files) => {
    if (!("TextDecoder" in window)) alert("This browser does not support decoding txt files. Please try the most recent version of Chrome, Firefox or Safari.");

    const file = files[0];
    setFile(file);
  }

  const onDrop = React.useCallback(files => {
    readFile(files);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/*' });

  const handleUpload = async () => {
    try {
      setLoading(true);
      const res = await Ajax.uploadFile(file, bucket);
      onUpload(res['800']);
    } catch (e) {
      onError(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className={className}>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {
          file 

          ?
            <Typography variant='h6'>File ready to upload</Typography>

          :

          isDragActive 
            ?
              <Typography variant='h6'>Drop file here</Typography>
            :
              <div className={className}>
                <Typography variant='h6'>Drag file here or click to select</Typography>
                <PublishIcon fontSize='large' />
              </div>
        }
      </div>
      { loading 
        ?
          <Button disabled>
            <CircularProgress />
          </Button>
        :
          <Button disabled={!Boolean(file)} onClick={handleUpload}>
            {file ? 'Upload' : 'Select a File'}
          </Button>
      }
      
    </div>
  )
}

export default FilePicker;