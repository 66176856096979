import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateAllIngredients } from '../../redux/actions';
import { useDeviceStyles } from '../../customHooks';
import Ajax from '../../Ajax';

import { Typography, TextField, MenuItem } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

import Search from '../../Components/Helpers/Search';

import { styles } from './styles';

const MealPlanner = (props) => {
  const { plan, onUpdate } = props;
  const classes = useDeviceStyles({ styles });
  console.log(plan);

  const dispatch = useDispatch();
  const allIngredients = useSelector(state => state.recipes.allIngredients);

  const [selectedRecipe, selectRecipe] = React.useState(null);
  const [entries, setEntries] = React.useState([]);
  const [search, setSearch] = React.useState('');
  const [recDetails, setRecDetails] = React.useState({
    diet: 'omnivore',
    servings: 1
  })

  // First time ingredient fetch
  React.useEffect(() => {
    const fetchAllIngredients = async () => {
      const res = await Ajax.getAllIngredients();
      dispatch(updateAllIngredients(res));
    }
    if (!allIngredients.length) fetchAllIngredients();
    
  }, [allIngredients, dispatch])

  React.useEffect(() => {
    if (!search) {
      setEntries([]);
    }
  }, [search])

  const handleSelect = (rec) => {
    setSearch('');
    selectRecipe(rec);
  }

  const handleChange = (e) => {
    setRecDetails({ ...recDetails, ...{[e.target.name]: e.target.value }});
  }

  const updatePlan = (y, x) => {
    if (!selectedRecipe) {
      window.alert('First search for a recipe!');
      return;
    }

    const update = [...plan];

    update[y].meals[x] = {
      id: selectedRecipe.id,
      title: selectedRecipe.title,
      servings: recDetails.servings,
      diet: recDetails.diet,
      primaryImage: selectedRecipe.primaryImage
    }

    onUpdate(update);
  }

  const handleRecDelete = (y, x) => {
    let update = [...plan];

    update[y].meals[x] = {};
    onUpdate(update);
  }

  const handleAddRow = () => {
    let update = [...plan];
    update.push(
      {title: 'New Row', meals: [{}, {}, {}, {}, {}, {}, {}]},
    );

    onUpdate(update);
  }

  const handleDeleteRow = (y) => {
    let update = [...plan];
    if (y === 0) {
      update.shift();
    } else if (y === update.length - 1) {
      update.pop();
    } else {
      update = update.slice(0, y).concat(update.slice(y + 1));
    }

    onUpdate(update);
  }

  return (
    <div className={classes.planWrapper}>
      <Typography>Selected Recipe</Typography>
      <div className={classes.recipeSelect}>
        <Search searchFunction={(searchVal) => Ajax.searchRecipes(searchVal, allIngredients)} onChange={setEntries} onSearch={setSearch} />
      </div>

      <div>
        {!search && selectedRecipe
          ? 
            <div className={classes.selectedRecipe}>
              <Typography>{selectedRecipe.title}</Typography>
              <img src={selectedRecipe.primaryImage} alt={selectedRecipe.title} style={{ height: 150 }} />
              <TextField label='Servings' type='number' name='servings' value={recDetails.servings} onChange={handleChange} style={{ marginRight: 5, marginBottom: 5 }} />
              <TextField id='Diet' label='Diet' value={recDetails.diet} select name='diet' onChange={handleChange}>
                <MenuItem value='omnivore'>Omnivore</MenuItem>
                <MenuItem value='vegetarian'>Vegetarian</MenuItem>
                <MenuItem value='vegan'>Vegan</MenuItem>
              </TextField>
            </div>
          :
            entries.map(rec => (
              <Typography onClick={() => handleSelect(rec)} className={classes.option}>{rec.title}</Typography>
            ))
        }
      </div>

      <div className={classes.planGrid}>
        {plan.map((row, y) => (
          <div className={classes.rowWrapper}>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography style={{ margin: '5px 10px 5px 0' }}>{row.title}</Typography>
              <DeleteIcon className={classes.icon} onClick={() => handleDeleteRow(y)}/>
            </div>

            <div className={classes.gridRow} key={y}>
              {row.meals.map((grid, x) => (
                <div className={classes.grid} key={`${y}-${x}`}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography style={{ marginLeft: 2 }}>{x  + 1}</Typography>
                    { grid.id ? <DeleteIcon className={classes.icon} onClick={() => handleRecDelete(y, x)}/>: null }  
                  </div>

                  {grid.id 
                    ? 
                      <div className={classes.recPreview}>
                        <Typography style={{ fontSize: 12, textAlign: 'center' }}>{grid.title}</Typography>
                        <img src={grid.primaryImage} style={{ height: 50 }} alt={grid.title} />
                        <Typography style={{ fontSize: 12 }}>{grid.diet}</Typography>
                        <Typography style={{ fontSize: 12 }}>Servings: {grid.servings}</Typography>
                      </div>
                    :
                      <div className={classes.recPreview}>
                        <AddIcon className={classes.icon} onClick={() => updatePlan(y, x)}/>
                    </div>
                  }
                </div>
              ))}
            </div>
          </div>
        ))}

        <AddIcon className={classes.icon} style={{ marginTop: 15 }} onClick={handleAddRow} />
      </div>
    </div>
  )
}

export default MealPlanner;