import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNotification, updateAllIngredients } from '../../redux/actions';
import { useDeviceStyles } from '../../customHooks';
import Ajax from '../../Ajax';

import { Typography, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import Ingredient from './Ingredient';
import GroupButtons from './GroupButtons';

import { styles } from './styles';

const RecipeIngredients = ({ label, diet, ingredientLists }) => {
  const dispatch = useDispatch();
  const classes = useDeviceStyles({ styles });

  const storedIngredients = useSelector(state => state.recipes.allIngredients);

  const [list, setList] = React.useState(null);
  const [ingredientDetails, setIngredientDetails] = React.useState([]);
  const [openIngredientIndex, setOpenIngredient] = React.useState(null);

  // Sets first time ingredient store for ingredient lists to reference
  React.useEffect(() => {
    const storeIngredients = async () => {
      const ings = await Ajax.getAllIngredients();
      dispatch(updateAllIngredients(ings));
    }

    if (!storedIngredients.length) storeIngredients();
    
  }, [storedIngredients, dispatch]);

  // Set list of appropriate diet
  React.useEffect(() => {
    if (ingredientLists && !list) {
      const dietList = ingredientLists.find(l => l.diet === diet);
      setList(dietList);
    }

  }, [ingredientLists, diet, list]);

  React.useEffect(() => {
    if (list && list.data) setIngredientDetails(list.data);
  }, [list])

  const handleUpdate = async () => {
    try {
      const res = await Ajax.updateIngredientList(list.id, { data: ingredientDetails });
  
      console.log(res);
      dispatch(setNotification({ msg: `${res.diet} ingredients updated`, severity: 'info'}));
  
      setList(res);
      setIngredientDetails(res.data);
    } catch (e) {
      dispatch(setNotification({ msg: e.message, severity: 'error'}));

    }
  }

  const handleAdd = (data) => {
    const details = data ? data : {
      id: null, 
      qty: 0, 
      group: '',
      type: '', 
      measurement: '', 
      substituteId: null 
    }
    const update = [...ingredientDetails];
    update.push(details);
    setOpenIngredient(update.length - 1);
    setIngredientDetails(update);
  }

  const handlePaste = () => {
    let details = localStorage.getItem('wwl-admin-copied-ing');
    details = details ? JSON.parse(details) : null;
    
    if (details) handleAdd(details);
  }

  const handleRemove = (index) => {
    const update = ingredientDetails.map((ing, i) => {
      if (i === index) return null;
      return ing;
    }).filter(ing => Boolean(ing));

    setIngredientDetails(update)
  }

  const handleCopyAll = () => {
    localStorage.setItem('wwl-admin-copied-set', JSON.stringify(ingredientDetails));
  }

  const handlePasteAll = () => {
    let details = localStorage.getItem('wwl-admin-copied-set');
    details = details ? JSON.parse(details) : null;

    if (details) {
      setIngredientDetails(details);
    }
    
  }

  const handleChange = (key, name, data) => {
    const update = ingredientDetails.map((ing, i) => {
      if (i === key) {
        return { ...ing, ...{ [name]: data }};
      }
      return ing;
    });

    setIngredientDetails(update)
  }

  const handleReorder = (index, direction, e) => {
    e.stopPropagation();
    let newPos = index + direction;
    if (newPos < 0) newPos = 0;
    if (newPos >= ingredientDetails.length) newPos = ingredientDetails.length - 1;

    const ingredient = ingredientDetails[index];

    let update = [...ingredientDetails.slice(0, index), ...ingredientDetails.slice(index + 1)];
    update = [...update.slice(0, newPos), ingredient, ...update.slice(newPos)];

    setIngredientDetails(update);
  }

  return (
    <div className={classes.set}>
      <div className={classes.titleRow}>
        <Typography variant='h5'>{label}</Typography>

        <GroupButtons 
          handleCopyAll={handleCopyAll}
          handlePaste={handlePaste}
          handlePasteAll={handlePasteAll}
          handleSave={handleUpdate}
        />

      </div>

      {ingredientDetails.map((row, i) => (
        <Ingredient 
          key={i} 
          details={{ ...row, key: i }} 
          moveUp={(e) => handleReorder(i, -1, e)}
          moveDown={(e) => handleReorder(i, 1, e)}
          isOpen={i === openIngredientIndex} 
          expand={() => setOpenIngredient(i)}
          collapse={() => setOpenIngredient(null)}
          onChange={handleChange} 
          onDelete={handleRemove} 
          storedIngredients={storedIngredients} 
        />
      ))}

      <Tooltip title='Add New Ingredient' placement='top'>
        <button style={{ backgroundColor: 'transparent', border: 'none'}} onClick={() => handleAdd(null)}>
          <AddIcon aria-label="add ingredient" className={classes.icon} style={{ marginTop: 15 }}  />
        </button>
      </Tooltip>

    </div>
  )
}

export default RecipeIngredients;