import React from 'react';
import Ajax from '../../Ajax';

import Entries from '../../Components/Entries';

const Ingredients = () => {
  const headCells = ['Title', 'Category', 'Density'];
  const rowProps = ['title', 'category', 'density'];

  const defaultValues = {
    title: 'New Ingredient',
    category: 'pantry',
  }

  return (
    <Entries 
      headCells={headCells}
      rowProps={rowProps}
      defaultValues={defaultValues}
      createFunction={Ajax.createIngredient}
      searchFunction={Ajax.searchIngredients} 
      requestData={Ajax.getAllIngredients} 
      requestCount={Ajax.countIngredients} 
      redirectLink='ingredients'
    />
  )

}

export default Ingredients;