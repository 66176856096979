import { createMuiTheme } from '@material-ui/core';

const wwlPink = '#FF4677';
const wwlBlue = '#101C70';
const wwlTeal = '#5EC9BE';
const wwlGreen = '#CDD421';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: wwlPink,
    },
    secondary: {
      main: wwlBlue,
    },
  },
});

// Component Props
theme.props = {
  MuiPaper: {
    elevation: 4,
  },
  MuiButton: {
    variant: 'contained',
    color: 'primary'
  },
  MuiRadio: {
    color: 'primary'
  },
  MuiSwitch: {
    color: 'primary'
  },
  MuiAlert: {
    variant: 'filled'
  },
  MuiTextField: {
    color: 'secondary'
  },
  MuiTabs: {
    indicatorColor: 'primary'
  },
  MuiPagination: {
    color: 'secondary'
  },
  MuiCheckbox: {
    color: 'primary'
  }
}

// Component CSS Rules
theme.overrides = {
  a: {
    root: {
      textDecoration: 'none'
    }
  },

  // Typography
  MuiTypography: {
    root: {
      fontFamily: 'GibsonRegular',
    },
    body2: {
      fontFamily: 'GibsonRegular',
      color: wwlBlue,
    },
    body1: {
      fontFamily: 'GibsonRegular',
      color: wwlBlue,
    },
    h6: {
      fontFamily: 'GibsonRegular',
      color: wwlBlue,
    },
    h5: {
      fontFamily: 'GibsonRegular',
      color: wwlBlue,
    },
    h4: {
      fontFamily: 'GibsonRegular',
      color: wwlBlue,
    },
    h3: {
      fontFamily: 'GibsonRegular',
      color: wwlBlue,
    },
    h2: {
      fontFamily: 'GibsonBold',
      color: wwlBlue,
    },
    h1: {
      fontFamily: 'GibsonBold',
      color: wwlBlue,
    },
  },

  // Paper
  MuiPaper: {
    root: {
      // backgroundColor: cyan[400]
    }
  },

  // Button
  MuiButton: {
    root: {
      borderRadius: '10px',
      textTransform: 'none',
      margin: '10px 5px',
      minWidth: '145px',
      '&&:hover': {
        backgroundColor: wwlPink,
        color: '#fff'
      }
    },
    containedPrimary: {
      backgroundColor: wwlPink,
      color: '#fff',

    },
    containedSecondary: {
      // fontWeight: '700'
    }
  },

  // Form Control
  MuiFormControlLabel: {
    label: {
      color: '#000'
    }
  },

  MuiTextField: {
    root: {
      minWidth: '250px'
    }
  },

  // Alert
  MuiAlert: {
    filledInfo: {
      backgroundColor: wwlBlue,
      color: '#fff'
    },
    filledSuccess: {
      backgroundColor: wwlGreen,
      color: '#fff'
    }
  }
}

export default theme;