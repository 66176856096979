import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import { useDeviceStyles } from '../../customHooks';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import IngInput from './IngInput';
import IngSearch from './IngSearch';

import { styles } from './styles';

const Ingredient = ({ onChange, onDelete, isOpen, expand, collapse, storedIngredients, details, moveUp, moveDown }) => {
  const { key, id: ingredientId, qty, group, prefix, type, notes, measurement, substituteId } = details;

  const classes = useDeviceStyles({ styles });

  const [associatedIngredient, setAssociatedIngredient] = React.useState(null);
  const [gfIngredient, setGFIngredient] = React.useState(null);

  React.useEffect(() => {
    if (ingredientId && storedIngredients.length) {
      const ing = storedIngredients.find(i => i.id === ingredientId);
      setAssociatedIngredient(ing);
    }

  }, [ingredientId, storedIngredients])

  React.useEffect(() => {
    if (substituteId && storedIngredients.length) {
      const ing = storedIngredients.find(i => i.id === substituteId);
      setGFIngredient(ing);
    }

  }, [substituteId, storedIngredients])


  const handleChange = (name, data) => {
    onChange(key, name, data);
  }

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete(key);
  }

  const handleCopy = (e) => {
    e.stopPropagation();
    localStorage.setItem('wwl-admin-copied-ing', JSON.stringify(details));
  }

  return (
    <Accordion className={classes.accordion} TransitionProps={{ unmountOnExit: true }} expanded={isOpen}>

      <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={isOpen ? collapse : expand}>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <Typography>{associatedIngredient?.title || 'New'}</Typography>
          <div style={{ width: '30%', display: 'flex', justifyContent: 'space-between' }} onClick={(e) => e.stopPropagation()}>
            <ArrowUpwardIcon onClick={moveUp} />
            <ArrowDownwardIcon onClick={moveDown} />
            <FileCopyIcon onClick={handleCopy} />
            <DeleteIcon onClick={handleDelete} />
          </div>
        </div>
      </AccordionSummary>

      <AccordionDetails>
        <IngInput autoFocus name='group' width={150} label='group' value={group} onChange={handleChange} />

        <IngInput name='qty' type='number' width={50} label='qty' value={qty} onChange={handleChange} />

        <IngInput name='prefix' width={100} label='prefix' value={prefix} onChange={handleChange} />

        <IngInput name='measurement' width={125} label='measurement' value={measurement} onChange={handleChange} 
          type='auto'
          options={[ { label: 'Ounce', prop: 'ounce' }, { label: 'Cup', prop: 'cup' }, { label: 'tsp', prop: 'teaspoon' }, { label: 'TBSP', prop: 'tablespoon' }  ]}
        />

        <IngSearch label='ingredient' name='id' currentSelection={associatedIngredient} onChange={handleChange} />

        <IngInput name='type' width={100} label='type' value={type} onChange={handleChange} 
          type='auto'
          options={[ { label: 'Liquid', prop: 'liquid' }, { label: 'Solid', prop: 'solid' }, { label: 'Whole', prop: 'whole' } ]}
        />

        <IngInput name='notes' width={150} label='notes' value={notes} onChange={handleChange} />

        <IngSearch label='gf substitute' name='substituteId' currentSelection={gfIngredient} onChange={handleChange} />

      </AccordionDetails>

    </Accordion>
  )
}

export default Ingredient;