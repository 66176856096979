import React from 'react';

import { FormControlLabel, Switch} from '@material-ui/core';


const SpecialSearch = ({ onChange, filters }) => {

  return (
    <section>
      <FormControlLabel
        control={ <Switch checked={filters.isAdmin} onChange={(e) => onChange('isAdmin', e.target.checked)} />}
        label='Admin'
      />
    </section>
  )
}

export default SpecialSearch;