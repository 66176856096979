import React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { setNotification } from '../../redux/actions';
import { useDeviceStyles } from '../../customHooks';
import Ajax from '../../Ajax';

import { Button, TextField, FormControlLabel, Switch, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import RichText from '../../Components/RichText';
import Loading from '../../Components/Helpers/Loading';
import DatePicker from '../../Components/Helpers/DatePicker';
import FilePicker from '../../Components/Helpers/FilePicker';
import MealPlanner from './MealPlanner';

import { defaultValues } from './defaultValues';
import { styles } from './styles';

const MealPlan = () => {
  const dispatch = useDispatch();
  const classes = useDeviceStyles({ styles });

  const [redirect, setRedirect] = React.useState(null);
  const [entry, setEntry] = React.useState(null);
  const [activeEdits, setActiveEdits] = React.useState(false);
  const [updates, setUpdates] = React.useState(defaultValues);

  React.useEffect(() => {
    const fetchEntry = async (id) => {
      const res = await Ajax.getMealPlan(id);
      setEntry(res);
      setUpdates({...res});
    }

    const id = window.location.href.match(/(?<=\/meal-plans\/)[\d]+/g); // might not work in Safari, check support for lookbehinds

    if (!entry && id && id[0]) {
      fetchEntry(id[0]);
    }
  }, []);

  const handleChange = (e, isCheckbox, manualField) => {
    setActiveEdits(true);

    if (manualField) {
      setUpdates({...updates, ...{ [manualField]: e }});
    } else {
      let val = isCheckbox ? e.target.checked : e.target.value;
      setUpdates({...updates, ...{ [e.target.name]: val }});
    }
  }

  const handleSave = async () => {
    try {
      const res = await Ajax.updateMealPlan(entry.id, { ...updates });

      setUpdates({...res});

      dispatch(setNotification({ msg: 'Entry updated', severity: 'info' }));

      setActiveEdits(false);
    } catch (e) {
      dispatch(setNotification({ msg: e.message, severity: 'error' }));
    }
  }

  const handleDelete = async () => {
    const confirm = window.confirm(`Delete meal plan ${entry.title}? ${entry.isAdmin ? '' : 'Warning! This is not an admin plan.'}`);
    if (!confirm) return;

    try {
      await Ajax.deleteMealPlan(entry.id);

      dispatch(setNotification({ msg: 'Entry deleted', severity: 'info' }));
      setRedirect('/meal-plans');
    } catch (e) {
      dispatch(setNotification({ msg: e.message, severity: 'error' }));
    }
  }

  const handleImageDelete = async () => {
    try {
      if (!updates.primaryImage) return;
  
      const fileName = updates.primaryImage.split('s3-us-west-2.amazonaws.com/')[1];
      const bucket = updates.primaryImage.match(/(?<=https:\/\/)[\w-]+/)[0];
  
      // Delete from S3
      await Ajax.deleteFile(fileName, bucket)
  
      handleChange('', false, 'primaryImage');
    } catch (e) {
      dispatch(setNotification({ msg: e.message, severity: 'error' }));
    }
  }

  const handleEditorChange = (data, name) => {
    setActiveEdits(true);
    setUpdates({...updates, ...{ [name]: data }});
  }

  const updatePlan = (data) => {
    setActiveEdits(true);
    setUpdates({...updates, ...{ plan: data }});
  }

  if (!entry) {
    return (
      <Loading height='25px' width='25px' />
    )
  }

  if (redirect) {
    return (
      <Redirect push to={redirect} />
    )
  }

  return (
    <main className={classes.main}>
      <div className={classes.row}>
        <Button disabled={!activeEdits} onClick={handleSave}>Save</Button>
        <Button onClick={handleDelete}>Delete</Button>
      </div>

      <div className={classes.row}>
        <TextField className={classes.title} id='Title' placeholder='Title' value={updates.title} name='title' onChange={handleChange} />
      </div>

      <div className={classes.row}>
        <TextField className={classes.title} id='Tags' placeholder='Tags' value={updates.tags} name='tags' onChange={handleChange} />
      </div>

      <div className={classes.row}>
        <FormControlLabel
          control={ <Switch checked={updates.isAdmin} onChange={(e) => handleChange(e, true)} name='isAdmin' />}
          label='Admin'
        />

        <DatePicker selectedDate={updates.date || new Date()} onChange={(date) => handleChange(date, false, 'date')} />
      </div>

      <div className={classes.row} style={{ marginBottom: 0, maxWidth: 400 }}>
        <Typography variant='body1'>Primary Image (800px x 400px)</Typography>
        { updates.primaryImage ? <DeleteIcon onClick={handleImageDelete} className={classes.icon} /> : null }
      </div>

      <div className={classes.row}>
        { updates.primaryImage 
          ? <img src={updates.primaryImage} className={classes.image} alt={entry.title} />

          : <FilePicker className={classes.filePicker} bucket='wwl-api-images' onUpload={(url) => handleChange(url, false, 'primaryImage')} onError={(e) => dispatch(setNotification({ msg: e.message, severity: 'error'}))}/>
        }
      </div>

      <Typography variant='h5'>Notes</Typography>

      <div className={classes.row}>
        <RichText name='notes' data={entry.notes} onChange={handleEditorChange} />
      </div>

      <Typography variant='h5'>Tips</Typography>

      <div className={classes.row}>
        <RichText name='tips' data={entry.tips} onChange={handleEditorChange} />
      </div>

      <Typography variant='h5'>Plan</Typography>
      <MealPlanner plan={updates.plan} onUpdate={updatePlan} />

    </main>
  )
}

export default MealPlan;