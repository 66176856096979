import React from 'react';
import { useDeviceStyles } from '../../customHooks';

import { Tooltip } from '@material-ui/core';

import FileCopyIcon from '@material-ui/icons/FileCopy';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import QueueIcon from '@material-ui/icons/Queue';
import SaveIcon from '@material-ui/icons/Save';

const styles = {
  icon: {
    color: '#5EC9BE',
    '&:hover': {
      cursor: 'pointer',
    }
  }
}

const GroupButtons = (props) => {
  const { handleCopyAll, handlePaste, handlePasteAll, handleSave } = props;
  const classes = useDeviceStyles({ styles });

  return (
    <div style={{ display: 'flex', width: 300, justifyContent: 'space-between'}}>
      <Tooltip title='Paste Single Ingredient' placement='top'>
        <AddCircleIcon onClick={handlePaste} className={classes.icon} />
      </Tooltip>

      <Tooltip title='Copy All Ingredients' placement='top'>
        <FileCopyIcon onClick={handleCopyAll} className={classes.icon} />
      </Tooltip>

      <Tooltip title='Paste All Ingredients' placement='top'>
        <QueueIcon onClick={handlePasteAll} className={classes.icon} />
      </Tooltip>

      <Tooltip title='Save Changes' placement='top'>
        <SaveIcon onClick={handleSave} className={classes.icon} />
      </Tooltip>
    </div>
  )
}

export default GroupButtons;