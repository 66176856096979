import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateAllIngredients } from '../../redux/actions';
import Ajax from '../../Ajax';

import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const IngSearch = (props) => {
  const { label, currentSelection, onChange, name } = props;

  const storedIngredients = useSelector(state => state.recipes.allIngredients);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const fetchAllIngredients = async () => {
      const res = await Ajax.getAllIngredients();
      dispatch(updateAllIngredients(res));
    }

    if (!storedIngredients.length) {
      fetchAllIngredients();
    }
  }, [storedIngredients])

  const handleChange = (e, val) => {
    const selection = storedIngredients.find(ing => ing.title === val);
    if (selection) {
      onChange(name, selection.id);
    }
  }


  return (
    <Autocomplete
      freeSolo
      onChange={handleChange}
      value={currentSelection?.title || ''}
      options={storedIngredients.map(i => i.title)}
      renderInput={(params) => (
        <TextField {...params} label={label} margin="normal" variant="outlined" />
      )}
    />
  )
}

export default IngSearch;