import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import UploadAdapter from './UploadAdapter';

// ClassicEditor
//     .create( document.querySelector( '#editor' ), {
//         link: {
//           defaultProtocol: 'http://',
//           decorators: {
//             openInNewTab: {
//                 mode: 'manual',
//                 label: 'Open in a new tab',
//                 attributes: {
//                     target: '_blank',
//                     rel: 'noopener noreferrer'
//                 }
//             }
//         }
//         }
//     })

const RichText = (props) => {
  const { data, onChange, name } = props;

  const handleChange = (e, editor) => {
    onChange(editor.getData(), name);
  }

  const onBlur = () => {
  }

  const onFocus = () => {
  }

  const onReady = (editor) => {
    editor.plugins.get( 'FileRepository' ).createUploadAdapter = (loader) => {
      return new UploadAdapter(loader);
    };
  }

  return (
    <CKEditor 
      editor={ ClassicEditor }
      data={data}
      onChange={handleChange}
      onBlur={onBlur}
      onFocus={onFocus}
      onReady={onReady}
    />
  )
}

export default RichText;