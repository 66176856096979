import Ajax from '../../Ajax';

class UploadAdapter {

  constructor(loader) {
    this.loader = loader;
  }

  // Starts the upload process.
  upload() {
    return this.loader.file.then(file => {
      return Ajax.uploadFile(file, 'wwl-recipe-description-images');
    })
  }

  // Aborts the upload process.
  abort() {
      // Reject the promise returned from the upload() method.
    //   server.abortUpload();
  }
}

export default UploadAdapter;