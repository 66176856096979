import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setNotification } from '../../redux/actions';

import { Snackbar, Typography } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Notify = () => {

  const dispatch = useDispatch();
  const notification = useSelector(state => state.navigation.notification);
  const [subMessage, setSubmessage] = React.useState(null);

  React.useEffect(() => {
    
    if (notification.subMessage) {
      if (notification.subMessage === 'standard error') {
        setSubmessage('Please try again or reach out to support@workweeklunch.com');
      } else {
        setSubmessage(notification.subMessage);
      }
    }

  }, [notification])

  const handleClose = (e, reason) => {
    if (reason === 'clickaway') return;

    dispatch(setNotification({ msg: '', severity: '' }));
  }

  if (!notification || !notification.msg) {
    return (
      <></>
    )
  }

  return (
    <Snackbar open={Boolean(notification.msg)} autoHideDuration={4000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={notification.severity || 'info'}>
        <Typography variant='body1' style={{ color: '#fff' }}>{notification.msg}</Typography>

        {subMessage 
          ? <Typography variant='body1' style={{ color: '#fff' }}>{subMessage}</Typography>
          : null 
        }
      </Alert>
    </Snackbar>
  )
};

export default Notify;