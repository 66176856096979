import React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { setNotification } from '../../redux/actions';
import { useDeviceStyles } from '../../customHooks';
import Ajax from '../../Ajax';

import { Button, TextField, FormControlLabel, Switch, Typography } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import DeleteIcon from '@material-ui/icons/Delete';

import RichText from '../../Components/RichText';
import Loading from '../../Components/Helpers/Loading';
import DatePicker from '../../Components/Helpers/DatePicker';
import FilePicker from '../../Components/Helpers/FilePicker';
import Instructions from './Instructions';
import Ingredients from '../../Components/RecipeIngredients';

import { defaultValues } from './defaultValues';

const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    width: '100%',
    maxWidth: '1000px',
    margin: '20px 0',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    width: '100%',
    '& div': {
      width: '100%',
      '& > input': {
        width: '100%'
      }
    }
  },
  image: {
    width: '800px'
  },
  icon: {
    color: '#5EC9BE',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  filePicker: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  }
}

const Recipe = () => {
  const dispatch = useDispatch();
  const classes = useDeviceStyles({ styles });

  const [redirect, setRedirect] = React.useState(null);
  const [entry, setEntry] = React.useState(null);
  const [activeEdits, setActiveEdits] = React.useState(false);
  const [updates, setUpdates] = React.useState(defaultValues);
  const [rating, setRating] = React.useState(null);
  const [numRatings, setNumRatings] = React.useState(null);

  React.useEffect(() => {
    const fetchEntry = async (id) => {
      const res = await Ajax.getRecipe(id);
      setEntry(res);
      setUpdates({...res});
    }

    const id = window.location.href.match(/(?<=\/recipes\/)[\d]+/g); // might not work in Safari, check support for lookbehinds

    if (!entry && id && id[0]) {
      fetchEntry(id[0]);
    }
  }, []);

  React.useEffect(() => {
    const fetchRatings = async () => {
      const recipeRatings = await Ajax.getAllRatings(entry.id);

      const numRatings = recipeRatings.length;
      setNumRatings(numRatings);

      if (!recipeRatings.length) {
        setRating('-');
      } else {
        const ratingsSum = recipeRatings.map(obj => obj.score).reduce((acc, curr) => acc + curr);
        setRating(parseFloat((ratingsSum / recipeRatings.length).toFixed(2)));
      }
    }

    if (entry && !rating) fetchRatings();
  }, [entry])

  const handleChange = (e, isCheckbox, manualField) => {
    if (e?.target?.name === 'isPublic' && e.target.checked) {
      const confirm = window.confirm(`Heads up! Toggling the public field means anyone can visit this recipe by directly visiting https://wwlprogram.com/#/recipes/${entry.id}`);
      if (!confirm) return;
    }

    setActiveEdits(true);

    if (manualField) {
      setUpdates({...updates, ...{ [manualField]: e }});
    } else {
      let val = isCheckbox ? e.target.checked : e.target.value;
      setUpdates({...updates, ...{ [e.target.name]: val }});
    }
  }

  const handleSave = async () => {
    try {
      if (!updates.isDraft && updates.reviewed) {
        const confirm = window.confirm('Heads up! This recipe is marked as reviewed and not a draft. Saving this will make this recipe live!');
        if (!confirm) return;
      }

      const res = await Ajax.updateRecipe(entry.id, { recipeParams: updates, ingredientListsParams: [] });

      setUpdates({...res.recipe});

      dispatch(setNotification({ msg: 'Entry updated', severity: 'info' }));

      setActiveEdits(false);
    } catch (e) {
      dispatch(setNotification({ msg: e.message, severity: 'error' }));
    }
  }

  const handleDelete = async () => {
    const confirm = window.confirm(`Delete recipe ${entry.title}?`);
    if (!confirm) return;

    try {
      await Ajax.deleteRecipe(entry.id);

      dispatch(setNotification({ msg: 'Entry deleted', severity: 'info' }));
      setRedirect('/recipes');
    } catch (e) {
      dispatch(setNotification({ msg: e.message, severity: 'error' }));
    }
  }

  const handleEditorChange = (data, name) => {
    setActiveEdits(true);
    setUpdates({...updates, ...{ [name]: data }});
  }

  const handleImageDelete = async () => {
    try {
      if (!updates.primaryImage) return;
  
      const fileName = updates.primaryImage.split('s3-us-west-2.amazonaws.com/')[1];
      const bucket = updates.primaryImage.match(/(?<=https:\/\/)[\w-]+/)[0];
  
      // Delete from S3 if not default logo image
      if (fileName && !fileName.includes('wwl-logo')) {
        await Ajax.deleteFile(fileName, bucket)
      }
  
      handleChange('', false, 'primaryImage');
    } catch (e) {
      dispatch(setNotification({ msg: e.message, severity: 'error' }));
    }
  }

  if (!entry) {
    return (
      <Loading height='25px' width='25px' />
    )
  }

  if (redirect) {
    return (
      <Redirect push to={redirect} />
    )
  }

  return (
    <main className={classes.main}>

      <div className={classes.row}>
        <Button disabled={!activeEdits} onClick={handleSave}>Save</Button>
        <Button onClick={handleDelete}>Delete</Button>
      </div>

      <div className={classes.row}>
        <TextField className={classes.title} id='Title' placeholder='Title' value={updates.title} name='title' onChange={handleChange} />
      </div>

      <div className={classes.row}>
        <TextField className={classes.title} id='Tags' placeholder='Tags' value={updates.tags} name='tags' onChange={handleChange} />
      </div>

      <div className={classes.row}>
        <FormControlLabel
          control={ <Switch checked={updates.isDraft} onChange={(e) => handleChange(e, true)} name='isDraft' />}
          label='Draft'
        />

        <FormControlLabel
          control={ <Switch checked={updates.reviewed} onChange={(e) => handleChange(e, true)} name='reviewed' />}
          label='Reviewed'
        />


        <FormControlLabel
          control={ <Switch checked={updates.isPublic} onChange={(e) => handleChange(e, true)} name='isPublic' />}
          label='Public'
        />

        <DatePicker selectedDate={updates.publishedDate || new Date()} onChange={(date) => handleChange(date, false, 'publishedDate')} />
      </div>

      <div className={classes.row} style={{ justifyContent: 'flex-start' }}>
        <StarIcon style={{ marginRight: 5, color: '#5EC9BE' }} />
        <Typography>{`${rating} with ${numRatings} votes`}</Typography>
      </div>

      <div className={classes.row} style={{ marginBottom: 0, maxWidth: 400 }}>
        <Typography variant='body1'>Primary Image (800px x 400px)</Typography>
        { updates.primaryImage ? <DeleteIcon onClick={handleImageDelete} className={classes.icon} /> : null }
      </div>

      <div className={classes.row}>
        { updates.primaryImage && updates.primaryImage !== 'https://wwl-api-images.s3-us-west-2.amazonaws.com/wwl-logo.png'
          ? <img src={updates.primaryImage} className={classes.image} alt={entry.title} />

          : <FilePicker className={classes.filePicker} bucket='wwl-api-images' onUpload={(url) => handleChange(url, false, 'primaryImage')} onError={(e) => dispatch(setNotification({ msg: e.message, severity: 'error'}))}/>
        }
      </div>

      <div className={classes.row}>
        <TextField id='Prep Time' label='Prep Time' type='number' value={updates.prepTime} name='prepTime' onChange={handleChange} />
        <TextField id='Cook Time' label='Cook Time' type='number' value={updates.cookTime} name='cookTime' onChange={handleChange} />
        <TextField id='Servings' label='Default Servings' type='number' value={updates.servings || 0} name='servings' onChange={handleChange} />
      </div>

      <Typography variant='h5'>Description</Typography>

      <div className={classes.row}>
        <RichText name='description' data={entry.description || ''} onChange={handleEditorChange} />
      </div>

      <Ingredients label='Omnivore Ingredients' diet='omnivore' ingredientLists={entry.IngredientLists} />
      <Instructions label='Omnivore Instructions' name='omnivoreInstructions' instructions={entry.omnivoreInstructions}  onChange={handleEditorChange} />

      <Ingredients label='Vegetarian Ingredients' diet='vegetarian' ingredientLists={entry.IngredientLists} />
      <Instructions label='Vegetarian Instructions' name='vegetarianInstructions' instructions={entry.vegetarianInstructions}  onChange={handleEditorChange} />

      <Ingredients label='Vegan Ingredients' diet='vegan' ingredientLists={entry.IngredientLists} />
      <Instructions label='Vegan Instructions' name='veganInstructions' instructions={entry.veganInstructions}  onChange={handleEditorChange} />

      <Ingredients label='Dairy Free Ingredients' diet='dairyFree' ingredientLists={entry.IngredientLists} />
      <Instructions label='Dairy Free Instructions' name='dairyFreeInstructions' instructions={entry.dairyFreeInstructions}  onChange={handleEditorChange} />

    </main>
  )
}

export default Recipe;