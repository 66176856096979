import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentPage, toggleSidebar } from '../../redux/actions';
import { Link, useHistory } from 'react-router-dom';
import { useDeviceStyles } from '../../customHooks';

import { Drawer, Typography } from '@material-ui/core';

import KitchenIcon from '@material-ui/icons/Kitchen';
import EventIcon from '@material-ui/icons/Event';
import PersonIcon from '@material-ui/icons/Person';
import ReceiptIcon from '@material-ui/icons/Receipt';

import { styles, mobileStyles, tabletStyles } from './styles';

const SideBar = (props) => {
  // Component Styles
  const classes = useDeviceStyles({ styles, mobileStyles, tabletStyles });
  //////////////////

  // Redux Store
  const dispatch = useDispatch();
  const currentPage = useSelector(state => state.navigation.currentPage);
  const sidebarIsOpen = useSelector(state => state.navigation.sidebarIsOpen);
  //////////////

  const history = useHistory();

  const handleNavigation = (loc) => {
    dispatch(setCurrentPage(loc));
  }

  const handleLogout = () => {
    localStorage.removeItem('wwl-mpp-token');
    dispatch(toggleSidebar(false));
    history.push('/');
  }

  const SideBarLink = (props) => {
    const { Icon, pageName, linkName } = props;

    return (
      <div className={classes.navElement} style={currentPage === pageName ? { color: '#FF4677' } : {}}>
        <Icon />
        <Link to={pageName} style={{width: '100%'}} onClick={() =>  handleNavigation(pageName)}>
          <Typography component="h3" variant="h5" className={classes.navLink} style={currentPage === pageName ? { color: '#FF4677' } : {}}>
            {linkName}
          </Typography>
        </Link>
      </div>
    )
  }

  return (
    <Drawer anchor='left' open={sidebarIsOpen} variant="persistent">
      <div className={classes.sidebar}>

        <img className={classes.logo} src='https://wwl-api-images.s3-us-west-2.amazonaws.com/wwl-logo.png' alt='Workweek Lunch Logo' />

        <div className={classes.navigation}>
          <SideBarLink Icon={PersonIcon} pageName='/users' linkName='Users' />

          <SideBarLink Icon={KitchenIcon} pageName='/ingredients' linkName='Ingredients' />

          <SideBarLink Icon={ReceiptIcon} pageName='/recipes' linkName='Recipes' />

          <SideBarLink Icon={EventIcon} pageName='/meal-plans' linkName='Meal Plans' />


          <div onClick={handleLogout} className={classes.navElement}>
            <Typography component="h3" variant="h5" className={classes.navLink}>
              Logout
            </Typography>
          </div>

        </div>
        
      </div>
    </Drawer>
  )
}

export default SideBar;