import React from 'react';
import { useDeviceStyles } from '../../customHooks';

import Pagination from '@material-ui/lab/Pagination';
import { TextField, MenuItem } from '@material-ui/core';

const styles = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '15px 0',
  },
  select: {
    width: 100
  }
}

const CustomPagination = (props) => {
  const { onChange, search, request, requestCount, filterFunctions, filters } = props;
  const classes = useDeviceStyles({ styles });

  const [perPage, setPerPage] = React.useState(25);
  const [page, setPage] = React.useState(1);
  const [total, setTotal] = React.useState(null);

  React.useEffect(() => {
    const requestTotal = async () => {
      const res = await requestCount();
      setTotal(res);
    }

    requestTotal();
    
  }, [filters])

  React.useEffect(() => {
    const requestResources = async () => {
      const start = page === 1 ? 1 : page  * perPage;

      const activeFilters = filters ? Object.keys(filters).filter(key => filters[key]) : [];

      if (!activeFilters.length) {
        const res = await request(start, perPage);
        onChange(res);
      } else {
        let allEntriesMap = {};
        let allEntries = [];
        
        for (let i = 0; i < activeFilters.length; i++) {
          const set = await filterFunctions[activeFilters[i]](-1, perPage);
          allEntries = [...allEntries, ...set];
          set.forEach(entry => {
            if (allEntriesMap[entry.id]) {
              allEntriesMap[entry.id]++;
            } else {
              allEntriesMap[entry.id] = 1;
            }
          });
        }
        console.log(allEntriesMap);
        // Ensures filters are treated with AND logic
        allEntries = allEntries.filter(e => allEntriesMap[e.id] >= activeFilters.length);

        setTotal(allEntries.length);
        onChange(allEntries);
      }
    }

    if (!search) requestResources();

  }, [page, perPage, search, filters])

  return (
    <div className={classes.wrapper}>
      <TextField className={classes.select} select label='per page' onChange={(e) => setPerPage(e.target.value)} value={perPage}>
        <MenuItem value={25}>25</MenuItem>
        <MenuItem value={50}>50</MenuItem>
        <MenuItem value={100}>100</MenuItem>
      </TextField>

      <Pagination count={Math.ceil(total / perPage)} onChange={(e, val) => setPage(val)} shape='rounded' />
    </div>
  )
}

export default CustomPagination;