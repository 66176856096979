// Action Types
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const SIGN_USER_OUT = 'SIGN_USER_OUT';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const UPDATE_FAVORITE_RECIPES = 'UPDATE_FAVORITE_RECIPES';
export const UPDATE_ALL_RECIPES = 'UPDATE_ALL_RECIPES';
export const UPDATE_FILTERED_RECIPES = 'UPDATE_FILTERED_RECIPES';
export const UPDATE_ALL_INGREDIENTS = 'UPDATE_ALL_INGREDIENTS';
export const UPDATE_SAVED_RECIPES = 'UPDATE_SAVED_RECIPES';
export const UPDATE_BOOKMARKED_RECIPES = 'UPDATE_BOOKMARKED_RECIPES';
export const SET_CURRENT_RECIPE = 'SET_CURRENT_RECIPE';
export const SET_WEEKLY_MEAL_PLAN = 'SET_WEEKLY_MEAL_PLAN';
export const SET_SHOPPING_LIST = 'SET_SHOPPING_LIST';
export const SET_WORKSHEET_OPEN = 'SET_WORKSHEET_OPEN';
export const UPDATE_USER_MEAL_PLANS = 'UPDATE_USER_MEAL_PLANS';
export const UPDATE_MEAL_PLANS = 'UPDATE_MEAL_PLANS';
export const SET_CURRENT_MEAL_PLAN = 'SET_CURRENT_MEAL_PLAN';
export const SET_DRAFT_MEAL_PLAN = 'SET_DRAFT_MEAL_PLAN';
export const SET_PREV_LOCATION = 'SET_PREV_LOCATION';
export const SET_CURRENT_SEARCH_TYPE = 'SET_CURRENT_SEARCH_TYPE';
export const SET_CURRENT_SEARCH_VALUE = 'SET_CURRENT_SEARCH_VALUE';
export const SET_CURRENT_SEARCH_PAGE = 'SET_CURRENT_SEARCH_PAGE';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';

// Actions
export function setCurrentUser(payload) {
  return { type: SET_CURRENT_USER, payload };
};

export function updateCurrentUser(payload) {
  return { type: UPDATE_CURRENT_USER, payload };
}

export function signUserOut(payload) {
  return { type: SIGN_USER_OUT, payload };
};

export function setCurrentPage(payload) {
  return { type: SET_CURRENT_PAGE, payload };
}

export function toggleSidebar(payload) {
  return { type: TOGGLE_SIDEBAR, payload };
}

export function updateFavoriteRecipes(payload) {
  return { type: UPDATE_FAVORITE_RECIPES, payload };
}

export function updateAllRecipes(payload) {
  return { type: UPDATE_ALL_RECIPES, payload };
}

export function updateFilteredRecipes(payload) {
  return { type: UPDATE_FILTERED_RECIPES, payload };
}

export function updateAllIngredients(payload) {
  return { type: UPDATE_ALL_INGREDIENTS, payload };
}

export function updateSavedRecipes(payload) {
  return { type: UPDATE_SAVED_RECIPES, payload };
}

export function updateBookmarkedRecipes(payload) {
  return { type: UPDATE_BOOKMARKED_RECIPES, payload };
}

export function setCurrentRecipe(payload) {
  return { type: SET_CURRENT_RECIPE, payload };
}

export function setWeeklyMealPlan(payload) {
  return { type: SET_WEEKLY_MEAL_PLAN, payload };
}

export function setShoppingList(payload) {
  return { type: SET_SHOPPING_LIST, payload };
}

export function setWorksheetOpen(payload) {
  return { type: SET_WORKSHEET_OPEN, payload };
}

export function updateUserMealPlans(payload) {
  return { type: UPDATE_USER_MEAL_PLANS, payload };
}

export function updateMealPlans(payload) {
  return { type: UPDATE_MEAL_PLANS, payload };
}

export function setCurrentMealPlan(payload) {
  return { type: SET_CURRENT_MEAL_PLAN, payload };
}

export function setDraftMealPlan(payload) {
  return { type: SET_DRAFT_MEAL_PLAN, payload };
}

export function setPrevLocation(payload) {
  return { type: SET_PREV_LOCATION, payload };
}

export function setCurrentSearchType(payload) {
  return { type: SET_CURRENT_SEARCH_TYPE, payload };
}

export function setCurrentSearchValue(payload) {
  return { type: SET_CURRENT_SEARCH_VALUE, payload };
}

export function setCurrentSearchPage(payload) {
  return { type: SET_CURRENT_SEARCH_PAGE, payload };
}

export function setNotification(payload) {
  return { type: SET_NOTIFICATION, payload };
}