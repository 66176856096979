import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import { setNotification } from './redux/actions.js';

export const useDeviceStyles = (props) => {

  let { tabletStyles, mobileStyles, styles } = props;
  if (!mobileStyles) mobileStyles = {...styles};
  if (!tabletStyles) tabletStyles = {...mobileStyles};

  const isTablet = useMediaQuery('@media only screen and (min-device-width: 768px) and (max-device-width: 1024px)', { noSsr: true });
  const isMobile = useMediaQuery('@media only screen and (min-device-width: 250px) and (max-device-width: 812px)', { noSsr: true });

  let selectedStyle;

  if (isTablet) {
    selectedStyle = tabletStyles;
  } else if (isMobile) {
    selectedStyle = mobileStyles;
  } else {
    selectedStyle = styles;
  }

  const useStyles = makeStyles(selectedStyle);
  return useStyles(selectedStyle);
}

/**
 * @param {dispatch}
 * @return {function}
 * Returns a function that accepts an Error and updates notification in Redux store
 */
export const useHandleError = (dispatch) => {

  return (e, subMessage = null) => {
    const errMessage = e.response && e.response.data && e.response.data.message
      ? e.response.data.message
      : e.message;
  
    dispatch(setNotification({ msg: errMessage, subMessage: subMessage || 'Please try again or contact support@workweeklunch.com', severity: 'error' }));
  }
}