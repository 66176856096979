export const defaultValues = {
  title: '',
  date: new Date(),
  isAdmin: false,
  primaryImage: '',
  notes: '',
  tips: '',
  plan: [
    {title: 'Breakfast', meals: [{}, {}, {}, {}, {}, {}, {}]}, 
    {title: 'Lunch', meals: [{}, {}, {}, {}, {}, {}, {}]}, 
    {title: 'Dinner', meals: [{}, {}, {}, {}, {}, {}, {}]}
  ],
  extraData: {},
  tags: ''
}