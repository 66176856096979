import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useDeviceStyles } from '../../customHooks';

import { Typography, Tooltip } from '@material-ui/core';
import ListAltIcon from '@material-ui/icons/ListAlt';

import { styles, mobileStyles } from './styles';

const formatTitle = (page) => {
  switch (page) {
    case 'users':
      return 'Users';
    case 'ingredients':
      return 'Ingredients';
    case 'recipes':
      return 'Recipes';
    case 'mealPlans':
      return 'Meal Plans';
    default :
      return 'WWL Admin'
  }
}

const NavHeader = () => {
  const classes = useDeviceStyles({ styles, mobileStyles });

  // Redux
  const dispatch = useDispatch();
  const currentPage = useSelector(state => state.navigation.currentPage);
  const currentUser = useSelector(state => state.user.currentUser);
  /////////
  
  // Component State
  const [redirectVal, setRedirectValue] = React.useState(null);
  const [shoppingList, setShoppingList] = React.useState(null);
  //////////////////

  React.useEffect(() => {
    if (currentUser && currentUser.extraData && currentUser.extraData.shoppingList && currentUser.extraData.shoppingList.length) {
      setShoppingList(currentUser.extraData.shoppingList);

    } else {
      const cachedList = localStorage.getItem('wwl-mpp-shopping-list');
      if (cachedList) setShoppingList(cachedList);
    }

  }, []);

  React.useEffect(() => {
    if (redirectVal) setRedirectValue(null);
  }, [redirectVal])


  if (redirectVal) {
    return (
      <Redirect push to={redirectVal} />
    )
  }

  if (!currentUser || !currentUser.email) {
    return (
      <></>
    )
  }
  
  return (
    <div className={classes.navHeader} >

      <Typography component='h5' variant='h5'>
        {formatTitle(currentPage)}
      </Typography>
      
      <div className={classes.buttonGroup}>
        { shoppingList && !window.location.href.includes('shopping-list')
          ? 
            <Tooltip
              title='Go to Shopping List'
            >

              <ListAltIcon className={classes.list} onClick={() => setRedirectValue('/shopping-list')} />
            </Tooltip>
          : null
        }

      </div>
    </div>
  )
}

export default NavHeader;