import React from 'react';
import { useDeviceStyles } from '../../customHooks';

import RichText from  '../../Components/RichText';
import { Typography } from '@material-ui/core';

const styles = {
  set: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '1000px',
    margin: '20px 0',
    alignItems: 'flex-start',

    '& > h5': {
      marginBottom: '15px'
    }
  }
}

const Instructions = (props) => {
  const { instructions, onChange, name, label } = props;
  const classes = useDeviceStyles({ styles });

  return (
    <div className={classes.set}>
      <Typography variant='h5'>{label}</Typography>

      <RichText name={name} data={instructions} onChange={onChange} />
    </div>

  )
}

export default Instructions;