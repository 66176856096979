import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateAllIngredients } from '../../redux/actions';
import Ajax from '../../Ajax';

import Entries from '../../Components/Entries';

const Recipes = () => {
  const headCells = [
    'ID',
    'Primary Image',
    'Title',
    'Draft',
    'Published Date',
    'Tags'
  ];

  const rowProps = [
    'id',
    'primaryImage',
    'title', 
    'isDraft',
    'publishedDate',
    'tags'
  ];

  const defaultValues = {
    title: 'New Recipe',
    isDraft: true,
    reviewed: false
  }

  const dispatch = useDispatch();
  const allIngredients = useSelector(state => state.recipes.allIngredients);

  React.useEffect(() => {
    const fetchAllIngredients = async () => {
      const res = await Ajax.getAllIngredients();
      dispatch(updateAllIngredients(res));
    }
    if (!allIngredients.length) fetchAllIngredients();
    
  }, [allIngredients, dispatch])

  return (
    <Entries 
      headCells={headCells}
      rowProps={rowProps}
      defaultValues={defaultValues}
      createFunction={Ajax.createRecipe}
      searchFunction={(query) => Ajax.searchRecipes(query, allIngredients)} 
      requestData={Ajax.getAllRecipes} 
      requestCount={Ajax.countRecipes} 
      redirectLink='recipes'
    />
  )

}

export default Recipes;