import React from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';

import theme from './theme';

// Helpers
import ScrollToTop from './Components/Helpers/ScrollToTop';
import NotFound from './Components/Helpers/NotFound';
import Notify from './Components/Helpers/Notify';
import ProtectedRoute from './Components/Helpers/ProtectedRoute';
import SideBar from './Components/SideBar';
import NavHeader from './Components/NavHeader';

// Views
import SignIn from './Views/SignIn';
import Users from './Views/Users';
import User from './Views/Users/User';
import Ingredients from './Views/Ingredients';
import Ingredient from './Views/Ingredients/Ingredient';
import Recipes from './Views/Recipes';
import Recipe from './Views/Recipes/Recipe';
import MealPlans from './Views/MealPlans';
import MealPlan from './Views/MealPlans/MealPlan';

function App({store}){

  return (
    <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          <Router>
            <ScrollToTop>

              <NavHeader />
              <SideBar />

              <Switch>
                <Route exact path='/' component={SignIn} />

                <ProtectedRoute exact path='/users' component={Users} />
                <ProtectedRoute exact path='/users/:id' component={User} />

                <ProtectedRoute exact path='/ingredients' component={Ingredients} />
                <ProtectedRoute exact path='/ingredients/:id' component={Ingredient} />

                <ProtectedRoute exact path='/recipes' component={Recipes} />
                <ProtectedRoute exact path='/recipes/:id' component={Recipe} />

                <ProtectedRoute exact path='/meal-plans' component={MealPlans} />
                <ProtectedRoute exact path='/meal-plans/:id' component={MealPlan} />

                <Route component={NotFound} />
              </Switch>

            </ScrollToTop>
          </Router>

          <Notify />
        </Provider>
    </MuiThemeProvider>
  );
}

export default App;
