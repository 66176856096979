import React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { setNotification } from '../../redux/actions';
import { useDeviceStyles } from '../../customHooks';
import Ajax from '../../Ajax';

import { Typography, Button, TextField, Switch, FormControlLabel, Tooltip, MenuItem } from '@material-ui/core';

import Loading from '../../Components/Helpers/Loading';

const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    width: '100%',
    maxWidth: '1000px',
    margin: '10px 0',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

const User = () => {
  const dispatch = useDispatch();
  const classes = useDeviceStyles({ styles });

  const [redirect, setRedirect] = React.useState(null);
  const [entry, setEntry] = React.useState(null);
  const [activeEdits, setActiveEdits] = React.useState(false);
  const [updates, setUpdates] = React.useState({
    isAdmin: false,
    isActive: false,
    primaryDiet: '',
    isGlutenFree: false,
    isDairyFree: false,
    unitPreference: ''
  });

  React.useEffect(() => {
    const fetchEntry = async (id) => {
      const res = await Ajax.getUser(id);
      setEntry(res);
      setUpdates({...res});
    }

    const id = window.location.href.match(/(?<=\/users\/)[\d]+/g);

    if (!entry && id && id[0]) {
      fetchEntry(id[0]);
    }
  }, []);

  const handleChange = (e) => {
    const val = e.target.value ? e.target.value : e.target.checked;
    setActiveEdits(true);
    setUpdates({...updates, ...{ [e.target.name]: val }});
  }

  const handleSave = async () => {
    try {
      const res = await Ajax.updateUser(updates.id, updates);

      setEntry(res);
      setUpdates({...res});

      dispatch(setNotification({ msg: 'Entry updated', severity: 'info' }));

      setActiveEdits(false);
    } catch (e) {
      dispatch(setNotification({ msg: e.message, severity: 'error' }));
    }
  }

  const handleDelete = async () => {
    const confirm = window.confirm(`Delete user ${entry.email}?`);
    if (!confirm) return;

    try {
      await Ajax.deleteUser(entry.id);

      dispatch(setNotification({ msg: 'Entry deleted', severity: 'info' }));
      setRedirect('/users');
    } catch (e) {
      dispatch(setNotification({ msg: e.message, severity: 'error' }));
    }
  }

  const memberfulLink = (id) => {
    return (
      <a 
        href={`https://workweeklunch.memberful.com/admin/members/${id}`} 
        target='_blank' rel='noopener noreferrer'
      >{id}</a>
    )
  }

  if (!entry) {
    return (
      <Loading height='25px' width='25px' />
    )
  }

  if (redirect) {
    return (
      <Redirect push to={redirect} />
    )
  }

  // TODO: fav recipes and meal plans

  return (
    <main className={classes.main}>

      <div className={classes.row}>
        <Button disabled={!activeEdits} onClick={handleSave}>Save</Button>
        <Button onClick={handleDelete}>Delete</Button>
      </div>

      <div className={classes.row}>
        <Typography>{entry.email}</Typography>
        <Typography>Memberful ID: {memberfulLink(entry.memberfulId)}</Typography>
      </div>

      <div className={classes.row}>
        <FormControlLabel
          control={ <Switch checked={updates.isAdmin} onChange={handleChange} name='isAdmin' />}
          label='Admin'
        />

        <Tooltip placement='top' title='Change in Memberful'>
          <FormControlLabel
            control={ <Switch checked={updates.isActive} disabled /> }
            label='Active Membership'
          />
        </Tooltip>
      </div>

      <div className={classes.row}>
        <TextField id='Primary Diet' label='Primary Diet' value={updates.primaryDiet} select name='primaryDiet' onChange={handleChange}>
          <MenuItem value='omnivore'>Omnivore</MenuItem>
          <MenuItem value='vegetarian'>Vegetarian</MenuItem>
          <MenuItem value='vegan'>Vegan</MenuItem>
        </TextField>

        <FormControlLabel
          control={ <Switch checked={Boolean(updates.isDairyFree)} onChange={handleChange} name='isDairyFree' />}
          label='Dairy Free'
        />

        <FormControlLabel
          control={ <Switch checked={Boolean(updates.isGlutenFree)} onChange={handleChange} name='isGlutenFree' />}
          label='Gluten Free'
        />
      </div>

      <div className={classes.row}>
        <TextField id='Unit Preference' label='Unit Preference' value={updates.unitPreference} select name='unitPreference' onChange={handleChange}>
          <MenuItem value='imperial'>Imperial</MenuItem>
          <MenuItem value='metric'>Metric</MenuItem>
        </TextField>
      </div>

    </main>
  )
}

export default User;