import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

const ProtectedRoute = (props) => {
  const Component = props.component;
  
  const currentUser = useSelector(state => state.user.currentUser);

  const isAuthenticated = Boolean(currentUser) && Boolean(currentUser.isAdmin);

  return (
    isAuthenticated 
      ? <Component {...props} />
      : <Redirect to={{ pathname: '/' }} />
  )
}

export default ProtectedRoute;