export const styles = {
  sidebar: {
    width: '175px',
    height: '100%',
    minHeight: '550px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    borderRight: '1px solid #878787',
  },
  navigation: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '70%',
    marginTop: '25px',
  },
  navElement: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginLeft: '5%',
    marginBottom: '10%',
    width: '85%',
    color: '#a8a4a4',
    '& h3': {
      '&:hover': {
        cursor: 'pointer'
      }
    },
    '& a': {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      textDecoration: 'none'
    },
  },
  navLink: {
    fontSize: '1rem',
    marginLeft: '5%',
    color: '#a8a4a4'
  },
  logo: {
    width: '100%'
  }
};

export const mobileStyles = {
  ...styles,
  sidebar: {
    ...styles.sidebar,
    width: '80vw',
  },
  navigation: {
    ...styles.navigation,
    height: '60vh',
    justifyContent: 'space-between',
  },
  navElement: {
    ...styles.navElement,
    marginBottom: '0'
  },
  giftCopy: {
    ...styles.giftCopy,
    fontSize: '0.85rem',
    marginBottom: '2%',
  },
  logo: {
    ...styles.logo,
    width: '80%',
    marginTop: '2%',
  },
  giftButton: {
    ...styles.giftButton,
    width: '60%',
    '& span':{
      fontSize: '0.8em'
    }
  },
  gift: {
    ...styles.gift,
    minHeight: '100px'
  }
};

export const tabletStyles = {
  ...styles,
  sidebar: {
    ...styles.sidebar,
    width: '30vw'
  }
};