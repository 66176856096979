import React from 'react';
import Ajax from '../../Ajax';

import Entries from '../../Components/Entries';
import SpecialSearch from './SpecialSearch';

const MealPlans = () => {
  const headCells = [
    'ID',
    'Admin',
    'Primary Image',
    'Title',
    'Date',
    'Tags',
    'User',
  ];

  const rowProps = [
    'id',
    'isAdmin', 
    'primaryImage',
    'title',
    'date',
    'tags',
    'UserId'
  ];

  const defaultValues = {
    title: 'New Meal Plan',
    date: new Date(),
    isAdmin: false,
    plan: [
      {title: 'Breakfast', meals: [{}, {}, {}, {}, {}, {}, {}, ]}, 
      {title: 'Lunch', meals: [{}, {}, {}, {}, {}, {}, {}, ]}, 
      {title: 'Dinner', meals: [{}, {}, {}, {}, {}, {}, {}, ]}
    ],
  }

  const [filters, setFilters] = React.useState({ isAdmin: false });

  const handleFilter = (key, val) => {
    setFilters(prevState => {
      return {
        ...prevState,
        ...{[key]: val}
      }
    })
  }

  return (
    <Entries 
      headCells={headCells}
      rowProps={rowProps}
      defaultValues={defaultValues}
      createFunction={Ajax.createMealPlan}
      searchFunction={Ajax.searchMealPlans} 
      specialSearch={<SpecialSearch onChange={(key, val) => handleFilter(key, val)} filters={filters} />}
      filters={filters}
      filterFunctions={{ isAdmin: Ajax.getAllAdminMealPlans }}
      requestData={Ajax.getAllMealPlans} 
      requestCount={Ajax.countMealPlans} 
      redirectLink='meal-plans'
    />
  )

}

export default MealPlans;