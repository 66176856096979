export const styles = {
  navHeader: {
    position: 'fixed',
    top: '0',
    height: '70px',
    width: '100vw',
    marginLeft: '175px',
    zIndex: '1',

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    borderLeft: '1px solid #878787',
    borderBottom: '1px solid #878787',

    '& h5': {
      marginLeft: '2%',
      width: '70%',
      color: '#0f1c70',
      fontFamily: 'GibsonBold'
    },
    '@media print': {
      display: 'none'
    },

    webkitBackfaceVisibility: 'hidden',
  },
  navHamburger: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  cartButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ff1452',
    color: '#ffffff',
    textTransform: 'none',
    marginRight: '5%',
    maxHeight: '35px',
    width: '130px',

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#fce5eb',
      color: '#ff1452'
    }
  },
  cartCopy: {
    display: 'block'
  },
  buttonGroup: {
    display: 'flex',
    width: '25%', 
    padding: '0 5%',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  list: {
    color: '#ff1452',

    '&:hover': {
      cursor: 'pointer'
    }

  },
}

export const mobileStyles = {
  ...styles,
  navHeader: {
    ...styles.navHeader,
    padding: '0 2% 0 2%',
  },
  buttonGroup: {
    ...styles.buttonGroup,
    flexDirection: 'column',
    width: '20%',
  },
  cartButton: {
    ...styles.cartButton,
    width: '80px'
  }
}