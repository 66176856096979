import React from 'react';
import { useDeviceStyles } from '../../customHooks';

import { Paper, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, TableSortLabel, Avatar } from '@material-ui/core';

import Loading from './Loading';

const styles = {
  true: {
    color: 'green',
  },
  false: {
    color: 'red'
  },
  table: {
    minHeight: 500,
  },
  tableRow: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}

const CustomTable = ({ headCells, rowProps, data, onChange, linkTo }) => {
  const classes = useDeviceStyles({ styles });

  const [orderBy, setOrderBy] = React.useState(0);
  const [order, setOrder] = React.useState('asc');
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    let timeout;

    if (!data.length) {
      setLoading(true);

      timeout = setTimeout(() => {
        setLoading(false);
      }, 5000)
    } else {
      clearTimeout(timeout);
      setLoading(false);
    }
  }, [data]);

  const ascSort = ((arr, prop) => {
    if (typeof arr[0][prop] === 'string') {
      return arr.sort((a, b) => a[prop] !== null && b[prop] !== null ? a[prop].localeCompare(b[prop]) : -1);
    }

    return arr.sort((a, b) => a[prop] - b[prop]);
  });

  const handleSort = (index) => {
    const sorted = ascSort(data, rowProps[index]);
    
    if (order === 'asc') {
      setOrder('desc');
      onChange(sorted.reverse())
    } else {
      setOrder('asc');
      onChange(sorted);
    }

    setOrderBy(index);
  }

  const createCell = (label, index) => {
    return (
      <TableCell key={index}>
        <TableSortLabel
          active={orderBy === index}
          direction={orderBy === index ? order : 'asc'}
          onClick={() => handleSort(index)}
        >
          {label}
        </TableSortLabel>
      </TableCell>
    )
  }

  const imageCell = (src, key, alt) => {
    return (
      <TableCell key={key}>
        <Avatar src={src} alt={alt} />
      </TableCell>
    )
  }

  if (loading) {
    return (
      <Loading height='50px' width='50px' />
    )
  }

  return (
    <TableContainer component={Paper} className={classes.table}>
      <Table>
        <TableHead>
          <TableRow>

            {headCells.map((cell, i) => (
              createCell(cell, i)
            ))}

          </TableRow>
        </TableHead>

        <TableBody>
          { data.map((row, i) => (
            <TableRow key={i} className={classes.tableRow} onClick={() => linkTo(row.id)}>
              { rowProps.map(prop => (
                typeof row[prop] === 'boolean'
                  ? <TableCell key={prop} className={row[prop] ? classes.true  : classes.false}>{row[prop] ? 'Yes' : 'No'}</TableCell>
                  : 
                    row[prop] && typeof row[prop] === 'string' && row[prop].includes('wwl-api-images') 
                      ? imageCell(row[prop], prop, row.title || 'Image') 
                      : <TableCell key={prop}>{row[prop]}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>

      </Table>
    </TableContainer>
  )
}

export default CustomTable;